import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const AgendarCita = () => {
  useEffect(() => {
    // Verificar si el script de Calendly ya está cargado
    if (!document.querySelector('script[src="https://assets.calendly.com/assets/external/widget.js"]')) {
      const script = document.createElement('script');
      script.src = 'https://assets.calendly.com/assets/external/widget.js';
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg mt-16">
      <Helmet>
        <title>Agendar Demostración - MiFacturador</title>
      </Helmet>
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">Agendar Demostración</h1>
      <p className="text-gray-600 mb-4">
        Para agendar una cita de demostración, por favor sigue los siguientes pasos:
      </p>
      <ol className="list-decimal list-inside text-gray-600 mb-6">
        <li>Haz clic en una fecha disponible en el calendario.</li>
        <li>Selecciona una hora que te convenga.</li>
        <li>Introduce tu nombre y dirección de correo electrónico.</li>
        <li>Haz clic en "Confirmar" para agendar tu cita.</li>
      </ol>
      <p className="text-gray-600 mb-4">
        Si prefieres, también puedes escribirnos por WhatsApp haciendo clic en el botón a continuación:
      </p>
      <a href="https://wa.me/+51967550393" target="_blank" rel="noopener noreferrer" className="block text-center mb-6">
        <button className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600">
          Escríbenos por WhatsApp
        </button>
      </a>
      <p className="text-gray-600 mb-4 text-center">O</p>
      <a href="#contacto" className="block text-center mb-6">
        <button className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
          Contáctanos
        </button>
      </a>
      <div className="calendly-container">
        <div className="calendly-inline-widget" data-url="https://calendly.com/mifacturador/demo" style={{ minWidth: '320px', height: '630px' }}></div>
      </div>
    </div>
  );
};

export default AgendarCita;