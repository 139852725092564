import React from 'react';

const PoliticasPrivacidad = () => {
  return (
    <article className="prose lg:prose-xl mx-auto my-8 p-8 bg-white shadow-md rounded-lg text-gray-700 mt-24">
      <header>
        <h1 className="text-4xl font-bold mb-8">Política de Privacidad de Mi Facturador</h1>
        <p className="text-lg text-gray-600">En Mi Facturador, nos comprometemos a proteger la privacidad de nuestros usuarios y garantizar la seguridad de sus datos personales. A continuación, detallamos cómo recopilamos, utilizamos y protegemos la información que obtenemos a través de nuestra aplicación.</p>
      </header>

      <section>
        <h2 className="text-3xl font-bold border-b border-gray-300 mt-16 pb-4">1. Información que Recopilamos</h2>
        <p>Para ofrecer nuestros servicios, recopilamos diversos tipos de información, que incluyen:</p>
        <ul className="list-disc mt-8 pl-6">
          <li className="mt-4"><strong>Información Personal:</strong> Recopilamos información que permite identificar al usuario, como su nombre, dirección de correo electrónico, número de teléfono, dirección fiscal, RUC, DNI y detalles de su empresa.</li>
          <li className="mt-4"><strong>Información de Facturación:</strong> Para emitir comprobantes electrónicos, recopilamos los datos fiscales necesarios, como detalles de facturas, boletas, notas de crédito y débito, guías de remisión, y otros documentos relacionados.</li>
          <li className="mt-4"><strong>Información Técnica:</strong> Cuando usas la app, también podemos recopilar información relacionada con el dispositivo que utilizas, como la dirección IP, el sistema operativo, el tipo de navegador y otros datos técnicos.</li>
        </ul>
      </section>

      <section>
        <h2 className="text-3xl font-bold border-b border-gray-300 mt-16 pb-4">2. Cómo Utilizamos la Información</h2>
        <p>La información que recopilamos se utiliza exclusivamente para los siguientes fines:</p>
        <ul className="list-disc mt-8 pl-6">
          <li className="mt-4">Emitir comprobantes electrónicos conforme a los lineamientos establecidos por la <strong>SUNAT</strong> y otras entidades reguladoras.</li>
          <li className="mt-4">Mejorar la experiencia del usuario mediante la personalización de la interfaz de la aplicación y recomendaciones de productos.</li>
          <li className="mt-4">Mantener una comunicación directa con el usuario para informarle sobre actualizaciones, cambios en los términos del servicio, soporte técnico y notificaciones de seguridad.</li>
          <li className="mt-4">Garantizar el cumplimiento de las leyes y regulaciones aplicables, incluyendo la prevención de fraudes y otros delitos cibernéticos.</li>
        </ul>
      </section>

      <section>
        <h2 className="text-3xl font-bold border-b border-gray-300 mt-16 pb-4">3. Protección de Datos</h2>
        <p>En <strong>Mi Facturador</strong>, tomamos medidas exhaustivas para proteger la información personal de nuestros usuarios:</p>
        <ul className="list-disc mt-8 pl-6">
          <li className="mt-4">Implementamos protocolos de seguridad como cifrado de datos (SSL) para proteger la información durante la transmisión.</li>
          <li className="mt-4">Acceso restringido: Solo el personal autorizado de Mi Facturador tiene acceso a los datos personales de los usuarios.</li>
          <li className="mt-4">Monitoreo continuo: Realizamos auditorías de seguridad periódicas para garantizar la protección de la información y prevenir accesos no autorizados.</li>
          <li className="mt-4">Almacenamiento seguro: Los datos personales se almacenan en servidores con alta seguridad y ubicaciones controladas, con políticas de acceso estrictas.</li>
        </ul>
        <p className="mt-8">Aunque nos esforzamos por implementar todas las medidas de seguridad necesarias, es importante destacar que ningún sistema de transmisión o almacenamiento de datos es completamente seguro. Por lo tanto, no podemos garantizar la seguridad absoluta de la información.</p>
      </section>

      <section>
        <h2 className="text-3xl font-bold border-b border-gray-300 mt-16 pb-4">4. Compartición de Información</h2>
        <p>No compartimos la información personal de nuestros usuarios con terceros, salvo en los siguientes casos:</p>
        <ul className="list-disc mt-8 pl-6">
          <li className="mt-4">Cuando es requerido por la ley o una orden judicial para cumplir con obligaciones legales.</li>
          <li className="mt-4">Para procesar pagos o emitir comprobantes electrónicos a través de proveedores autorizados por <strong>SUNAT</strong> u otras entidades reguladoras.</li>
          <li className="mt-4">En el caso de una fusión, adquisición o venta de activos, la información del usuario podría ser transferida, siempre notificando previamente a los usuarios.</li>
        </ul>
      </section>

      <section>
        <h2 className="text-3xl font-bold border-b border-gray-300 mt-16 pb-4">5. Derechos del Usuario</h2>
        <p>Como usuario de <strong>Mi Facturador</strong>, tienes ciertos derechos sobre tu información personal, que incluyen:</p>
        <ul className="list-disc mt-8 pl-6">
          <li className="mt-4">Acceder a los datos personales que hemos recopilado sobre ti.</li>
          <li className="mt-4">Solicitar la corrección de cualquier información incorrecta o desactualizada.</li>
          <li className="mt-4">Solicitar la eliminación de tus datos personales, siempre que no exista una obligación legal para retener dicha información.</li>
          <li className="mt-4">Oponerte al procesamiento de tus datos personales en circunstancias específicas.</li>
        </ul>
        <p className="mt-8">Para ejercer cualquiera de estos derechos, puedes contactarnos a través de nuestro equipo de soporte, y responderemos a tus solicitudes de manera oportuna.</p>
      </section>

      <section>
        <h2 className="text-3xl font-bold border-b border-gray-300 mt-16 pb-4">6. Cambios en la Política de Privacidad</h2>
        <p>Nos reservamos el derecho de modificar esta Política de Privacidad en cualquier momento. Si realizamos cambios significativos, notificaremos a los usuarios a través de la aplicación o mediante el correo electrónico registrado. Recomendamos revisar esta página periódicamente para estar informado sobre cómo protegemos tu información.</p>
      </section>

      <section>
        <h2 className="text-3xl font-bold border-b border-gray-300 mt-16 pb-4">7. Contacto</h2>
        <p>Si tienes preguntas o inquietudes sobre nuestra Política de Privacidad o sobre el tratamiento de tus datos, no dudes en contactarnos:</p>
        <ul className="list-disc mt-8 pl-6">
          <li className="mt-4"><strong>Email:</strong> oscar.rodriguez@perubi.com</li>
          <li className="mt-4"><strong>Teléfono:</strong> +51 940 769 577</li>
        </ul>
      </section>

      <footer className="mt-16">
        <p className="text-lg font-bold">Fecha de Vigencia</p>
        <p>Esta Política de Privacidad entra en vigor a partir del <strong>1 de octubre de 2024</strong>.</p>
      </footer>
    </article>
  );
};

export default PoliticasPrivacidad;