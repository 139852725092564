import React, { useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../config/firebaseConfig';

const ConsultaDocumentos = () => {
  const [tipoDocumento, setTipoDocumento] = useState('');
  const [rucEmisor, setRucEmisor] = useState('');
  const [serie, setSerie] = useState('');
  const [correlativo, setCorrelativo] = useState('');
  const [montoTotal, setMontoTotal] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [consultaMessage, setConsultaMessage] = useState('');
  const [pdfLink, setPdfLink] = useState(null); // Nuevo estado para almacenar el enlace del PDF
  const [xmlLink, setXmlLink] = useState(null); // Nuevo estado para almacenar el enlace del XML

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true); // Iniciar el indicador de carga

    try {
      const token = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit' });
      console.log('ReCAPTCHA token:', token); // Verifica que el token se esté generando

      const response = await fetch('https://us-central1-mifacturadorya.cloudfunctions.net/verifyRecaptcha', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      const data = await response.json();
      console.log('ReCAPTCHA verification response:', data); // Verifica la respuesta del servidor

      if (data.success) {
        console.log('Iniciando consulta con los siguientes datos:');
        console.log('Tipo de Documento:', tipoDocumento);
        console.log('RUC Emisor:', rucEmisor);
        console.log('Serie:', serie);
        console.log('Correlativo:', correlativo);
        console.log('Monto Total:', montoTotal);

        const q = query(
          collection(db, tipoDocumento),
          where('ruc', '==', rucEmisor),
          where('serie', '==', serie),
          where('correlativo', '==', parseInt(correlativo)), // Convertir correlativo a número
          where('subtotal', '==', parseFloat(montoTotal)) // Asegurarse de que el campo se llama 'subtotal'
        );

        const querySnapshot = await getDocs(q);
        console.log('Resultados de la consulta:', querySnapshot.size);

        if (querySnapshot.empty) {
          setConsultaMessage('No se encontraron documentos.');
          setPdfLink(null); // No hay PDF para mostrar
          setXmlLink(null); // No hay XML para mostrar
        } else {
          querySnapshot.forEach((doc) => {
            console.log('Documento encontrado:', doc.id, ' => ', doc.data());
            setPdfLink(doc.data().pdf); // Almacena el enlace del PDF
            setXmlLink(doc.data().xml); // Almacena el enlace del XML
          });
          setConsultaMessage('Documento encontrado. Puede descargarlo desde los siguientes enlaces.');
        }
      } else {
        console.error('ReCAPTCHA verification failed:', data); // Agrega más depuración
        setConsultaMessage('Hubo un error al verificar el reCAPTCHA. Por favor, inténtalo de nuevo.');
        setPdfLink(null); // No hay PDF para mostrar
        setXmlLink(null); // No hay XML para mostrar
      }
    } catch (error) {
      if (error.code === 'failed-precondition') {
        console.error('Firestore error: Se requiere un índice compuesto para esta consulta. Sigue el enlace proporcionado en la consola para crear el índice.');
      } else {
        console.error('Error during document query:', error); // Verifica cualquier error durante la consulta
      }
      setConsultaMessage('Hubo un error al consultar documentos. Por favor, inténtalo de nuevo.');
      setPdfLink(null); // No hay PDF para mostrar
      setXmlLink(null); // No hay XML para mostrar
    } finally {
      setIsLoading(false); // Detener el indicador de carga
    }
  };

  return (
    <div className="container mx-auto p-4 mt-20">
      <h2 className="text-3xl font-bold mb-6 text-center">Consulta de Comprobantes de Pago</h2>
      <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-md max-w-lg mx-auto">
        <div className="mb-4">
          <label className="block text-gray-700">Tipo de Documento</label>
          <select
            value={tipoDocumento}
            onChange={(e) => setTipoDocumento(e.target.value)}
            className="w-full p-2 border rounded"
            required
          >
            <option value="">Seleccione...</option>
            <option value="factura">Factura</option>
            <option value="boleta">Boleta</option>
            <option value="NC">Nota de Crédito</option>
            <option value="ND">Nota de Débito</option>
            <option value="GR">Guía de Remisión</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">RUC Emisor</label>
          <input
            type="text"
            value={rucEmisor}
            onChange={(e) => setRucEmisor(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">N° Serie</label>
          <input
            type="text"
            value={serie}
            onChange={(e) => setSerie(e.target.value)}
            className="w-full p-2 border rounded"
            maxLength="4"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">N° Correlativo</label>
          <input
            type="text"
            value={correlativo}
            onChange={(e) => setCorrelativo(e.target.value)}
            className="w-full p-2 border rounded"
            maxLength="8"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Subtotal</label>
          <input
            type="number"
            step="0.01"
            value={montoTotal}
            onChange={(e) => setMontoTotal(e.target.value)}
            className="w-full p-2 border rounded"
            required
          />
        </div>
        <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 w-full" disabled={isLoading}>
          {isLoading ? 'Consultando...' : 'Consultar'}
        </button>
      </form>
      {consultaMessage && <p className="mt-4 text-red-500 text-center">{consultaMessage}</p>}
      {pdfLink && (
        <div className="mt-4 text-center">
          <a href={pdfLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
            Descargar PDF
          </a>
        </div>
      )}
      {xmlLink && (
        <div className="mt-4 text-center">
          <a href={xmlLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
            Descargar XML
          </a>
        </div>
      )}
    </div>
  );
};

export default ConsultaDocumentos;