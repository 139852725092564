import React from 'react';

const SobreNosotros = () => {
  return (
    <article className="prose lg:prose-xl mx-auto my-8 p-8 bg-white shadow-md rounded-lg text-gray-700 mt-24">
      <header>
        <h1 className="text-4xl font-bold mb-8">Sobre Nosotros</h1>
        <p className="text-lg text-gray-600">En <strong>Mi Facturador</strong>, estamos comprometidos en ofrecer soluciones tecnológicas innovadoras para simplificar y optimizar los procesos de facturación electrónica de empresas de todo tamaño. Nuestra misión es facilitar el cumplimiento normativo de las empresas y permitirles crecer a través de la eficiencia y la automatización.</p>
      </header>

      <section>
        <h2 className="text-3xl font-bold border-b border-gray-300 mt-16 pb-4">Nuestra Historia</h2>
        <p>Mi Facturador nace en respuesta a la creciente demanda de herramientas digitales que ayuden a las empresas peruanas a adaptarse al entorno de la <strong>facturación electrónica</strong>, cumpliendo con las normativas establecidas por la <strong>SUNAT</strong>. Desde nuestros inicios, hemos trabajado para brindar una plataforma intuitiva, segura y que permita a nuestros clientes concentrarse en lo que realmente importa: hacer crecer su negocio.</p>
      </section>

      <section>
        <h2 className="text-3xl font-bold border-b border-gray-300 mt-16 pb-4">Nuestra Misión</h2>
        <p>En Mi Facturador, nuestra misión es ayudar a las empresas a gestionar su facturación de manera eficiente, reduciendo errores y tiempos de procesamiento mediante la automatización. Queremos que cada empresa, sin importar su tamaño, tenga acceso a la tecnología que necesita para cumplir con las regulaciones fiscales de forma sencilla y asequible.</p>
      </section>

      <section>
        <h2 className="text-3xl font-bold border-b border-gray-300 mt-16 pb-4">Nuestros Valores</h2>
        <p>Nos guiamos por los siguientes principios:</p>
        <ul className="list-disc mt-8 pl-6">
          <li className="mt-4"><strong>Innovación:</strong> Estamos siempre a la vanguardia de la tecnología, mejorando continuamente nuestra plataforma para ofrecer nuevas funcionalidades y soluciones adaptadas a las necesidades de nuestros clientes.</li>
          <li className="mt-4"><strong>Compromiso con el Cliente:</strong> Nos esforzamos en brindar un servicio al cliente excepcional, escuchando sus necesidades y proporcionándoles las herramientas para alcanzar el éxito.</li>
          <li className="mt-4"><strong>Confianza y Seguridad:</strong> Entendemos la importancia de la seguridad en los procesos financieros, por eso nos aseguramos de que nuestra plataforma cumpla con los más altos estándares de seguridad y confidencialidad de la información.</li>
          <li className="mt-4"><strong>Adaptabilidad:</strong> Sabemos que cada empresa es única, por lo que ofrecemos soluciones personalizables que se adaptan a las particularidades de cada sector.</li>
        </ul>
      </section>

      <section>
        <h2 className="text-3xl font-bold border-b border-gray-300 mt-16 pb-4">Nuestro Equipo</h2>
        <p>Mi Facturador está compuesto por un equipo de profesionales con experiencia en desarrollo de software, finanzas y normativas fiscales. Nuestro equipo trabaja incansablemente para ofrecer una solución robusta, fácil de usar y acorde a las necesidades del mercado peruano. Cada miembro de nuestro equipo aporta su experiencia y dedicación para crear una plataforma que realmente marque la diferencia para nuestros clientes.</p>
      </section>

      <section>
        <h2 className="text-3xl font-bold border-b border-gray-300 mt-16 pb-4">Por Qué Elegir Mi Facturador</h2>
        <p>En un entorno empresarial que se mueve rápidamente, la eficiencia es clave. <strong>Mi Facturador</strong> te ofrece:</p>
        <ul className="list-disc mt-8 pl-6">
          <li className="mt-4"><strong>Automatización completa:</strong> Emitimos facturas, boletas y otros comprobantes electrónicos de forma automática y conforme a la normativa SUNAT.</li>
          <li className="mt-4"><strong>Fácil integración:</strong> Nuestra plataforma se integra sin problemas con tus sistemas de contabilidad y ERP, facilitando la gestión diaria.</li>
          <li className="mt-4"><strong>Accesibilidad en cualquier lugar:</strong> Accede a nuestra plataforma desde cualquier dispositivo, ya sea móvil o de escritorio, para gestionar tus operaciones cuando lo necesites.</li>
          <li className="mt-4"><strong>Actualizaciones constantes:</strong> Nos mantenemos al día con la normativa peruana y realizamos actualizaciones constantes para asegurar que siempre estés cumpliendo con las regulaciones fiscales.</li>
        </ul>
      </section>

      <section>
        <h2 className="text-3xl font-bold border-b border-gray-300 mt-16 pb-4">Contáctanos</h2>
        <p>¿Tienes preguntas o necesitas más información? Estamos aquí para ayudarte. Puedes ponerte en contacto con nosotros a través de:</p>
        <ul className="list-disc mt-8 pl-6">
        <li className="mt-4"><strong>Email:</strong> oscar.rodriguez@perubi.com</li>
        <li className="mt-4"><strong>Teléfono:</strong> +51 940 769 577</li>
        </ul>
      </section>

      <footer className="mt-16">
        <p className="text-lg font-bold">Última Actualización</p>
        <p>Esta sección de <strong>Sobre Nosotros</strong> fue actualizada por última vez el <strong>1 de setiembre de 2024</strong>.</p>
      </footer>
    </article>
  );
};

export default SobreNosotros;