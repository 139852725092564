import React, { useState } from 'react';

const faqData = [
  {
    question: '¿Cómo integro la app con mi impresora térmica?',
    answer: 'Nuestra aplicación es compatible con la mayoría de las impresoras térmicas Bluetooth. La conexión es simple: solo empareja tu dispositivo con la impresora desde la configuración de la app.'
  },
  {
    question: '¿Funciona en todos los dispositivos móviles?',
    answer: 'Efectivamente, nuestra app está optimizada para iOS y Android, además tiene versión web, asegurando compatibilidad con la gran mayoría de dispositivos móviles actuales y PC.'
  },
  {
    question: '¿Cómo puedo actualizar la aplicación?',
    answer: 'Las actualizaciones se realizan automáticamente desde la tienda de aplicaciones de tu dispositivo. Asegúrate de tener activadas las actualizaciones automáticas.'
  },
  {
    question: '¿Qué métodos de pago acepta la app?',
    answer: 'Nuestra app acepta pagos con tarjeta de crédito, débito y transferencias bancarias. También puedes usar plataformas de pago como PayPal.'
  },
  {
    question: '¿Es segura la información de mis clientes?',
    answer: 'Sí, la seguridad de la información de tus clientes es nuestra prioridad. Utilizamos encriptación de extremo a extremo para proteger todos los datos.'
  },
  {
    question: '¿Puedo personalizar los recibos?',
    answer: 'Sí, puedes personalizar los recibos con el logo de tu empresa, información de contacto y mensajes personalizados.'
  },
  {
    question: '¿Cómo puedo contactar al soporte técnico?',
    answer: 'Puedes contactar al soporte técnico a través de nuestro formulario de contacto en la app o enviando un correo a soporte@tuapp.com.'
  },
  {
    question: '¿La app tiene un costo?',
    answer: 'Ofrecemos una versión gratuita con funciones básicas y planes de suscripción para funciones avanzadas. Consulta nuestra página de precios para más detalles.'
  },
  {
    question: '¿Puedo usar la app sin conexión a internet?',
    answer: 'Sí, puedes usar la app sin conexión a internet para realizar ventas. Los datos se sincronizarán automáticamente cuando vuelvas a estar en línea.'
  },
  {
    question: '¿Cómo puedo recuperar mi contraseña?',
    answer: 'Puedes recuperar tu contraseña desde la pantalla de inicio de sesión de la app. Haz clic en "Olvidé mi contraseña" y sigue las instrucciones.'
  }
];

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const firstColumnData = faqData.slice(0, 5);
  const secondColumnData = faqData.slice(5);

  return (
    <section id="faq" className="faq bg-white py-16 px-4 text-center">
      <h3 className="section-title text-3xl mb-10 font-bold">Preguntas Frecuentes</h3>
      <div className="accordion-container flex flex-wrap justify-center gap-5">
        <div className="accordion-column flex-1 min-w-[300px] max-w-[600px]">
          {firstColumnData.map((item, index) => (
            <div key={index} className="accordion-item bg-gray-100 rounded-lg mb-2 shadow">
              <button
                className={`accordion-button bg-blue-600 text-white py-3 px-5 w-full text-left border-none outline-none cursor-pointer text-lg transition duration-300 ${activeIndex === index ? 'bg-blue-700' : ''}`}
                onClick={() => toggleAccordion(index)}
              >
                {item.question}
              </button>
              <div className={`accordion-content px-5 py-3 ${activeIndex === index ? 'block' : 'hidden'}`}>
                <p className="m-0">{item.answer}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="accordion-column flex-1 min-w-[300px] max-w-[600px]">
          {secondColumnData.map((item, index) => (
            <div key={index + 5} className="accordion-item bg-gray-100 rounded-lg mb-2 shadow">
              <button
                className={`accordion-button bg-blue-600 text-white py-3 px-5 w-full text-left border-none outline-none cursor-pointer text-lg transition duration-300 ${activeIndex === index + 5 ? 'bg-blue-700' : ''}`}
                onClick={() => toggleAccordion(index + 5)}
              >
                {item.question}
              </button>
              <div className={`accordion-content px-5 py-3 ${activeIndex === index + 5 ? 'block' : 'hidden'}`}>
                <p className="m-0">{item.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;