import React, { useState } from 'react';
import screenshot1 from '../assets/images/screenshot1.png';
import screenshot2 from '../assets/images/screenshot2.png';
import screenshot3 from '../assets/images/screenshot3.png';
import screenshot4 from '../assets/images/screenshot4.png';
import screenshot5 from '../assets/images/screenshot5.png';
import screenshot6 from '../assets/images/screenshot6.png';
import screenshot7 from '../assets/images/screenshot7.png';
import screenshot8 from '../assets/images/screenshot8.png';
import screenshot9 from '../assets/images/screenshot9.png';
import screenshot10 from '../assets/images/screenshot10.png';
import screenshot11 from '../assets/images/screenshot11.png';
import screenshot12 from '../assets/images/screenshot12.png';

const images = [
  screenshot1, screenshot2, screenshot3, screenshot4, screenshot5, screenshot6,
  screenshot7, screenshot8, screenshot9, screenshot10, screenshot11, screenshot12
];

const Screenshots = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 2 : prevIndex - 2));
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex >= images.length - 2 ? 0 : prevIndex + 2));
  };

  return (
    <section id="capturas" className="screenshots bg-gray-100 py-16 px-4 text-center">
      <h3 className="section-title text-3xl mb-10 font-bold">Screenshots</h3>
      <div className="carousel relative max-w-full overflow-hidden">
        <button className="carousel-button prev absolute top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white text-2xl p-2 z-10 left-2" onClick={prevSlide}>❮</button>
        <div className="carousel-track-container relative overflow-hidden">
          <ul className="carousel-track flex transition-transform duration-500" style={{ transform: `translateX(-${currentIndex * 50}%)` }}>
            {images.map((image, index) => (
              <li key={index} className="carousel-slide flex-shrink-0 w-1/2 md:w-1/5 p-2">
                <img src={image} alt={`Captura de pantalla ${index + 1}`} className="carousel-image w-full h-auto max-h-80 object-contain rounded-lg" />
              </li>
            ))}
          </ul>
        </div>
        <button className="carousel-button next absolute top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white text-2xl p-2 z-10 right-2" onClick={nextSlide}>❯</button>
      </div>
    </section>
  );
};

export default Screenshots;