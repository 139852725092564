import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../config/firebaseConfig';

const BlogPost = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [allPosts, setAllPosts] = useState([]);
  const [tagCounts, setTagCounts] = useState({});
  const [showFilteredResults, setShowFilteredResults] = useState(false);

  useEffect(() => {
    const fetchPost = async () => {
      const q = query(collection(db, 'blogPost'), where('slug', '==', slug));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        setPost(querySnapshot.docs[0].data());
      } else {
        console.log('No such document!');
      }
    };

    fetchPost();
  }, [slug]);

  useEffect(() => {
    const fetchPosts = async () => {
      const q = query(collection(db, 'blogPost'));
      const querySnapshot = await getDocs(q);
      const posts = querySnapshot.docs.map(doc => doc.data());
      setAllPosts(posts);
      setFilteredPosts(posts);

      // Contar la frecuencia de cada etiqueta
      const tagFrequency = {};
      posts.forEach(post => {
        if (post.tags) {
          post.tags.forEach(tag => {
            if (tagFrequency[tag]) {
              tagFrequency[tag]++;
            } else {
              tagFrequency[tag] = 1;
            }
          });
        }
      });
      setTagCounts(tagFrequency);
    };

    fetchPosts();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const filtered = allPosts.filter(post =>
        post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        post.excerpt.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredPosts(filtered);
      setShowFilteredResults(true);
    } else {
      setFilteredPosts(allPosts);
      setShowFilteredResults(false);
    }
  }, [searchTerm, allPosts]);

  const handleCategoryClick = (category) => {
    const filtered = allPosts.filter(post => post.categories && post.categories.includes(category));
    setFilteredPosts(filtered);
    setShowFilteredResults(true);
  };

  const handleTagClick = (tag) => {
    const filtered = allPosts.filter(post => post.tags && post.tags.includes(tag));
    setFilteredPosts(filtered);
    setShowFilteredResults(true);
  };

  if (!post) {
    return <div>Loading...</div>;
  }

  const postDate = post.date.toDate();

  return (
    <div className="container mx-auto p-6 mt-16">
      <div className="flex flex-wrap -mx-4">
        <div className="w-full lg:w-2/3 px-4">
          {showFilteredResults ? (
            <div className="filtered-results">
              <h2 className="text-2xl font-bold mb-4">Resultados de búsqueda</h2>
              <ul className="list-disc list-inside">
                {filteredPosts.map((filteredPost, index) => (
                  <li key={index}>
                    <a href={`/blog/${filteredPost.slug}`} className="text-blue-500 hover:underline">
                      {filteredPost.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div className="blog-post-content p-4 md:p-10 bg-white shadow-lg rounded-lg">
              <h1 className="text-4xl font-bold mb-6">{post.title}</h1>
              <p className="text-gray-600 mb-4">{post.excerpt}</p>
              <p className="text-gray-500 mb-4">Publicado {postDate.toLocaleDateString()}</p>
              {post.imageUrl && (
                <div className="relative overflow-hidden" style={{ height: 'auto' }}>
                  <img src={post.imageUrl} alt={post.title} className="w-full h-full object-cover rounded-t-lg mb-4" style={{ objectPosition: 'top' }} />
                </div>
              )}
              <div className="text-gray-700" dangerouslySetInnerHTML={{ __html: post.contentHTML }} />
            </div>
          )}
        </div>
        <div className="w-full lg:w-1/3 px-4 mt-8 lg:mt-0">
          <div className="bg-white p-6 shadow-lg rounded-lg menu">
            <h2 className="text-2xl font-bold mb-4">Buscar</h2>
            <input
              type="text"
              placeholder="Buscar..."
              className="w-full p-2 mb-4 border rounded"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <h2 className="text-2xl font-bold mb-4">Categorías</h2>
            <ul className="list-disc list-inside">
              {post.categories && post.categories.map((category, index) => (
                <li key={index}>
                  <button onClick={() => handleCategoryClick(category)} className="text-blue-500 hover:underline">
                    {category}
                  </button>
                </li>
              ))}
            </ul>
            <h2 className="text-2xl font-bold mb-4">Tags</h2>
            <div className="flex flex-wrap">
              {post.tags && post.tags.map((tag, index) => (
                <button key={index} onClick={() => handleTagClick(tag)} className="bg-gray-200 text-gray-700 px-2 py-1 rounded mr-2 mb-2 hover:bg-gray-300">
                  {tag}
                </button>
              ))}
            </div>
            <h2 className="text-2xl font-bold mb-4">Nube de Tags</h2>
            <div className="flex flex-wrap">
              {Object.keys(tagCounts).map((tag, index) => (
                <button
                  key={index}
                  onClick={() => handleTagClick(tag)}
                  className="m-1"
                  style={{ fontSize: `${10 + tagCounts[tag] * 2}px` }}
                >
                  {tag}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;