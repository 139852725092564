import React from 'react';

const TerminosCondiciones = () => {
  return (
    <article className="prose lg:prose-xl mx-auto my-8 p-8 bg-white shadow-md rounded-lg text-gray-700 mt-24">
      <header>
        <h1 className="text-4xl font-bold mb-8">Términos y Condiciones de Uso de Mi Facturador</h1>
        <p className="text-lg text-gray-600">Al utilizar Mi Facturador, el usuario acepta y se compromete a cumplir con los siguientes términos y condiciones. Estos términos regulan el uso de la aplicación, los servicios ofrecidos y la relación entre Mi Facturador y sus usuarios.</p>
      </header>

      <section>
        <h2 className="text-3xl font-bold border-b border-gray-300 mt-16 pb-4">1. Aceptación de los Términos</h2>
        <p>El uso de la aplicación Mi Facturador implica la aceptación total y sin reservas de estos términos y condiciones. Si no está de acuerdo con alguna parte de estos términos, el usuario debe abstenerse de utilizar la aplicación.</p>
      </section>

      <section>
        <h2 className="text-3xl font-bold border-b border-gray-300 mt-16 pb-4">2. Modificaciones de los Términos</h2>
        <p>Nos reservamos el derecho de modificar estos Términos y Condiciones en cualquier momento. Si se realizan cambios significativos, notificaremos a los usuarios a través de la aplicación o mediante correo electrónico. Es responsabilidad del usuario revisar periódicamente estos términos para estar al tanto de cualquier cambio.</p>
      </section>

      <section>
        <h2 className="text-3xl font-bold border-b border-gray-300 mt-16 pb-4">3. Uso de la Aplicación</h2>
        <p>El usuario se compromete a utilizar Mi Facturador de manera responsable, respetando las leyes y regulaciones aplicables. El uso indebido de la aplicación puede dar lugar a la suspensión o cancelación de la cuenta del usuario, a discreción de Mi Facturador.</p>
        <ul className="list-disc mt-8 pl-6">
          <li className="mt-4">No está permitido utilizar la aplicación para fines ilegales o fraudulentos.</li>
          <li className="mt-4">El usuario debe proporcionar información veraz y actualizada al crear una cuenta y al emitir comprobantes electrónicos.</li>
          <li className="mt-4">El usuario no debe intentar acceder a las funciones de la aplicación de manera no autorizada ni interferir con su funcionamiento.</li>
        </ul>
      </section>

      <section>
        <h2 className="text-3xl font-bold border-b border-gray-300 mt-16 pb-4">4. Creación de Cuenta y Responsabilidad del Usuario</h2>
        <p>Para utilizar Mi Facturador, el usuario debe crear una cuenta proporcionando información precisa. Es responsabilidad del usuario mantener la confidencialidad de su cuenta y contraseña. Mi Facturador no será responsable por el uso no autorizado de la cuenta del usuario.</p>
        <p className="mt-8">El usuario es responsable de todas las actividades realizadas a través de su cuenta, incluyendo la emisión de comprobantes electrónicos, la gestión de productos y el acceso a datos de terceros.</p>
      </section>

      <section>
        <h2 className="text-3xl font-bold border-b border-gray-300 mt-16 pb-4">5. Facturación Electrónica</h2>
        <p>Mi Facturador permite a los usuarios emitir comprobantes electrónicos como facturas, boletas de venta, notas de crédito, notas de débito y guías de remisión, cumpliendo con las normativas de la <strong>SUNAT</strong> y otras entidades reguladoras.</p>
        <ul className="list-disc mt-8 pl-6">
          <li className="mt-4">El usuario es responsable de asegurar que la información ingresada en los comprobantes electrónicos sea correcta y precisa.</li>
          <li className="mt-4">Mi Facturador no se hace responsable por errores en la información proporcionada por el usuario o por el uso incorrecto del sistema de facturación.</li>
          <li className="mt-4">El usuario debe cumplir con todas las normativas fiscales establecidas por la SUNAT, incluyendo la conservación de los comprobantes emitidos según lo establecido por la legislación vigente.</li>
        </ul>
      </section>

      <section>
        <h2 className="text-3xl font-bold border-b border-gray-300 mt-16 pb-4">6. Propiedad Intelectual</h2>
        <p>Todos los derechos de propiedad intelectual relacionados con la aplicación Mi Facturador, incluyendo su diseño, código, interfaces y contenido, son propiedad exclusiva de Mi Facturador o sus licenciantes. El usuario no tiene derecho a copiar, modificar, distribuir o reproducir ninguna parte de la aplicación sin nuestro consentimiento previo por escrito.</p>
      </section>

      <section>
        <h2 className="text-3xl font-bold border-b border-gray-300 mt-16 pb-4">7. Limitación de Responsabilidad</h2>
        <p>Mi Facturador se proporciona "tal cual", sin garantías de ningún tipo, ya sean expresas o implícitas. No garantizamos que la aplicación estará libre de errores, interrupciones o fallos de seguridad. En ningún caso seremos responsables por daños directos, indirectos, incidentales o consecuentes derivados del uso de la aplicación.</p>
        <p className="mt-8">El usuario acepta que utiliza la aplicación bajo su propio riesgo y responsabilidad.</p>
      </section>

      <section>
        <h2 className="text-3xl font-bold border-b border-gray-300 mt-16 pb-4">8. Suspensión o Terminación del Servicio</h2>
        <p>Mi Facturador se reserva el derecho de suspender o cancelar el acceso a la aplicación en cualquier momento, sin previo aviso, si detectamos incumplimientos de estos Términos y Condiciones o cualquier comportamiento que consideremos inapropiado o perjudicial para el servicio o sus usuarios.</p>
      </section>

      <section>
        <h2 className="text-3xl font-bold border-b border-gray-300 mt-16 pb-4">9. Ley Aplicable y Jurisdicción</h2>
        <p>Estos Términos y Condiciones se rigen por las leyes de la República del Perú. Cualquier disputa relacionada con el uso de la aplicación será resuelta ante los tribunales competentes de Lima, Perú.</p>
      </section>

      <section>
        <h2 className="text-3xl font-bold border-b border-gray-300 mt-16 pb-4">10. Contacto</h2>
        <p>Si tienes alguna pregunta sobre estos Términos y Condiciones o sobre el uso de Mi Facturador, puedes contactarnos a través de los siguientes medios:</p>
        <ul className="list-disc mt-8 pl-6">
        <li className="mt-4"><strong>Email:</strong> oscar.rodriguez@perubi.com</li>
        <li className="mt-4"><strong>Teléfono:</strong> +51 940 769 577</li>
        </ul>
      </section>

      <footer className="mt-16">
        <p className="text-lg font-bold">Fecha de Actualización</p>
        <p>Estos Términos y Condiciones fueron actualizados por última vez el <strong>1 de setiembre de 2024</strong>.</p>
      </footer>
    </article>
  );
};

export default TerminosCondiciones;