import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs, query, orderBy, limit } from 'firebase/firestore';
import { db } from '../config/firebaseConfig';

const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const q = query(collection(db, 'blogPost'), orderBy('date', 'desc'), limit(3));
      const querySnapshot = await getDocs(q);
      const postsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setBlogPosts(postsData);
    };

    fetchPosts();
  }, []);

  return (
    <section id="lastpost" className="blog py-10 bg-gray-100">
      <h3 className="section-title text-2xl text-center mb-10 font-bold text-gray-800">Últimas Entradas del Blog</h3>
      <div className="blog-posts flex flex-wrap justify-center">
        {blogPosts.map((post) => (
          <article key={post.id} className="blog-post bg-white border border-gray-300 rounded-lg shadow-md m-2 p-5 w-full md:w-1/3 transition-transform duration-300 hover:transform hover:-translate-y-2 hover:shadow-lg">
            {post.imageUrl && (
              <img src={post.imageUrl} alt={post.title} className="w-full h-48 object-cover rounded-t-lg mb-4" />
            )}
            <h4 className="blog-post-title text-xl mb-2 font-bold text-gray-800">{post.title}</h4>
            <p className="blog-post-excerpt text-gray-600 mb-4">
              {post.excerpt.length > 150 ? `${post.excerpt.slice(0, 150)}...` : post.excerpt}
            </p>
            <Link to={`/blog/${post.slug}`} className="read-more text-blue-600 font-bold hover:text-blue-800">Leer más</Link>
          </article>
        ))}
      </div>
    </section>
  );
};

export default Blog;