import React from 'react';

const PlanesTarifas = () => {
  const whatsappNumber = "+51967550393"; // Reemplaza con tu número de WhatsApp

  const getWhatsappLink = (plan) => {
    const message = `Hola, estoy interesado en adquirir el ${plan} de Mi Facturador.`;
    return `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`;
  };

  return (
    <section className="bg-gradient-to-r from-blue-500 to-purple-600 py-12 text-white">
      <div className="container mx-auto text-center">
        <h2 className="text-5xl font-extrabold mb-12">Planes y Tarifas</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-blue-100 text-gray-800 border p-8 rounded-lg shadow-lg transform transition duration-500 hover:scale-105">
            <h3 className="text-3xl font-bold mb-4">Plan Básico</h3>
            <p className="text-lg mb-4">Ideal para pequeñas empresas</p>
            <p className="text-4xl font-extrabold mb-4">S/ 25/mes</p>
            <ul className="list-disc list-inside mb-6">
              <li className="mb-2">Hasta 200 comprobantes mensuales</li>
              <li className="mb-2">1 Usuario</li>
              <li className="mb-2">1 Sucursal</li>
              <li className="mb-2">300 Notas de Venta</li>
              <li className="mb-2">APP - Android, Iphone y Web </li>
              <li className="mb-2">Soporte básico</li>
              <li className="mb-2">Acceso a todas las funcionalidades</li>
            </ul>
            <a href={getWhatsappLink('Plan Básico')} className="bg-blue-500 text-white py-2 px-6 rounded-full font-bold transition duration-300 hover:bg-blue-700" target="_blank" rel="noopener noreferrer">Contactar por WhatsApp</a>
          </div>
          <div className="bg-green-100 text-gray-800 border p-8 rounded-lg shadow-lg transform transition duration-500 hover:scale-105">
            <h3 className="text-3xl font-bold mb-4">Plan Profesional</h3>
            <p className="text-lg mb-4">Para empresas en crecimiento</p>
            <p className="text-4xl font-extrabold mb-4">S/ 50/mes</p>
            <ul className="list-disc list-inside mb-6">
              <li className="mb-2">Hasta 800 comprobantes mensuales</li>
              <li className="mb-2">1 Usuario</li>
              <li className="mb-2">1 Sucursal</li>
              <li className="mb-2">1000 Notas de Venta</li>
              <li className="mb-2">APP - Android, Iphone y Web </li>
              <li className="mb-2">Soporte prioritario</li>
              <li className="mb-2">Acceso a todas las funcionalidades</li>
            </ul>
            <a href={getWhatsappLink('Plan Profesional')} className="bg-green-500 text-white py-2 px-6 rounded-full font-bold transition duration-300 hover:bg-green-700" target="_blank" rel="noopener noreferrer">Contactar por WhatsApp</a>
          </div>
          <div className="bg-yellow-100 text-gray-800 border p-8 rounded-lg shadow-lg transform transition duration-500 hover:scale-105">
            <h3 className="text-3xl font-bold mb-4">Plan Empresarial</h3>
            <p className="text-lg mb-4">Para grandes empresas</p>
            <p className="text-4xl font-extrabold mb-4">S/ 100/mes</p>
            <ul className="list-disc list-inside mb-6">
              <li className="mb-2">Comprobantes ilimitados</li>
              <li className="mb-2">2 Usuarios</li>
              <li className="mb-2">2 Sucursales</li>
              <li className="mb-2">Notas de Venta Ilimitado</li>
              <li className="mb-2">APP - Android, Iphone, Mac y Web </li>
              <li className="mb-2">Soporte dedicado</li>
              <li className="mb-2">Acceso a todas las funcionalidades</li>
            </ul>
            <a href={getWhatsappLink('Plan Empresarial')} className="bg-yellow-500 text-white py-2 px-6 rounded-full font-bold transition duration-300 hover:bg-yellow-700" target="_blank" rel="noopener noreferrer">Contactar por WhatsApp</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlanesTarifas;