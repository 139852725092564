import React, { useEffect, useState } from 'react';
import { collection, getDocs, addDoc, query, where, serverTimestamp } from 'firebase/firestore';
import { db } from '../config/firebaseConfig';

const Blog = () => {
  const [allPosts, setAllPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [tagCounts, setTagCounts] = useState({});
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedTag, setSelectedTag] = useState('');
  const [email, setEmail] = useState('');
  const [subscriptionMessage, setSubscriptionMessage] = useState('');
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const postsPerPage = 10;

  useEffect(() => {
    const fetchPosts = async () => {
      const querySnapshot = await getDocs(collection(db, 'blogPost'));
      const posts = querySnapshot.docs.map(doc => doc.data());

      // Ordenar las entradas por fecha en orden descendente
      posts.sort((a, b) => b.date.seconds - a.date.seconds);

      setAllPosts(posts);
      setFilteredPosts(posts);

      // Contar la frecuencia de cada etiqueta
      const tagFrequency = {};
      posts.forEach(post => {
        if (post.tags) {
          post.tags.forEach(tag => {
            if (tagFrequency[tag]) {
              tagFrequency[tag]++;
            } else {
              tagFrequency[tag] = 1;
            }
          });
        }
      });
      setTagCounts(tagFrequency);
    };

    fetchPosts();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const filtered = allPosts.filter(post =>
        post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        post.excerpt.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredPosts(filtered);
    } else {
      setFilteredPosts(allPosts);
    }
  }, [searchTerm, allPosts]);

  // Calcular los posts actuales
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  // Cambiar de página
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleCategoryClick = (category) => {
    const filtered = allPosts.filter(post => post.categories && post.categories.includes(category));
    setFilteredPosts(filtered);
    setSelectedCategory(category);
    setSelectedTag(''); // Resetear el tag seleccionado
    setCurrentPage(1); // Resetear a la primera página
  };

  const handleTagClick = (tag) => {
    const filtered = allPosts.filter(post => post.tags && post.tags.includes(tag));
    setFilteredPosts(filtered);
    setSelectedTag(tag);
    setSelectedCategory(''); // Resetear la categoría seleccionada
    setCurrentPage(1); // Resetear a la primera página
  };

  const handleSubscription = async (e) => {
    e.preventDefault();
    if (!email) {
      setSubscriptionMessage('Por favor, ingresa un correo electrónico válido.');
      return;
    }

    setIsLoading(true); // Iniciar el indicador de carga

    try {
      const token = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit' });
      console.log('ReCAPTCHA token:', token); // Verifica que el token se esté generando
      setRecaptchaValue(token);

      const response = await fetch('https://us-central1-mifacturadorya.cloudfunctions.net/verifyRecaptcha', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      const data = await response.json();
      console.log('ReCAPTCHA verification response:', data); // Verifica la respuesta del servidor

      if (data.success) {
        // Verificar si el correo ya existe en la colección
        const q = query(collection(db, 'suscripcion'), where('email', '==', email));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          setSubscriptionMessage('Este correo ya está suscrito.');
        } else {
          await addDoc(collection(db, 'suscripcion'), { 
            email, 
            fechaSuscripcion: serverTimestamp() 
          });
          setSubscriptionMessage('¡Gracias por suscribirte!');
          setEmail('');
        }
      } else {
        console.error('ReCAPTCHA verification failed:', data); // Agrega más depuración
        setSubscriptionMessage('Hubo un error al verificar el reCAPTCHA. Por favor, inténtalo de nuevo.');
      }
    } catch (error) {
      console.error('Error during subscription:', error); // Verifica cualquier error durante la suscripción
      setSubscriptionMessage('Hubo un error al suscribirte. Por favor, inténtalo de nuevo.');
    } finally {
      setIsLoading(false); // Detener el indicador de carga 
    }
  };

  return (
    <div className="container mx-auto p-6 mt-16">
      <div className="flex flex-wrap -mx-4">
        <div className="w-full lg:w-2/3 px-4">
          <h1 className="text-4xl font-bold mb-6">Blog</h1>
          {selectedCategory && (
            <h2 className="text-2xl font-bold mb-4">Categoría: {selectedCategory}</h2>
          )}
          {selectedTag && (
            <h2 className="text-2xl font-bold mb-4">Etiqueta: {selectedTag}</h2>
          )}
          <div>
            {currentPosts.map((post, index) => (
              <div key={index} className="mb-8 flex flex-col md:flex-row">
                {post.imageUrl && (
                  <a href={`/blog/${post.slug}`} className="mr-4 mb-4 md:mb-0">
                    <div className="w-full md:w-64 h-64 overflow-hidden rounded">
                      <img src={post.imageUrl} alt={post.title} className="object-cover w-full h-full" />
                    </div>
                  </a>
                )}
                <div className="flex flex-col justify-between">
                  <div className="flex flex-wrap mb-2">
                    {post.categories && post.categories.map((category, idx) => (
                      <button
                        key={idx}
                        onClick={() => handleCategoryClick(category)}
                        className="bg-blue-200 text-blue-800 text-sm px-2 py-1 rounded mr-2 mb-2 hover:underline"
                      >
                        {category}
                      </button>
                    ))}
                  </div>
                  <a href={`/blog/${post.slug}`} className="text-blue-500 hover:underline">
                    <h2 className="text-2xl font-bold mb-2">{post.title}</h2>
                  </a>
                  <div className="flex items-center text-gray-500 mb-2">
                    <svg className="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 20 20">
                      <path d="M6 2a1 1 0 112 0v1h4V2a1 1 0 112 0v1h1a2 2 0 012 2v11a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h1V2zM5 7v9h10V7H5z" />
                    </svg>
                    {new Date(post.date.seconds * 1000).toLocaleDateString('es-ES', {
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric'
                    }).toUpperCase()}
                  </div>
                  <p className="text-gray-600">{post.excerpt.slice(0, 200)}{post.excerpt.length > 200 ? '...' : ''}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center mt-6">
            {Array.from({ length: Math.ceil(filteredPosts.length / postsPerPage) }, (_, index) => (
              <button
                key={index}
                onClick={() => paginate(index + 1)}
                className={`mx-1 px-3 py-1 rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
        <div className="w-full lg:w-1/3 px-4">
          <div className="bg-white p-6 shadow-lg rounded-lg menu">
            <h2 className="text-2xl font-bold mb-4">Suscríbete a nuestro boletín</h2>
            <form onSubmit={handleSubscription}>
              <input
                type="email"
                placeholder="Tu correo electrónico"
                className="w-full p-2 mb-4 border rounded"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded mt-4" disabled={isLoading}>
                {isLoading ? 'Cargando...' : 'Suscribirse'}
              </button>
            </form>
            {subscriptionMessage && (
              <p className="mt-4 text-green-500">{subscriptionMessage}</p>
            )}
            <h2 className="text-2xl font-bold mb-4 mt-8">Buscar</h2>
            <input
              type="text"
              placeholder="Buscar..."
              className="w-full p-2 mb-4 border rounded"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <h2 className="text-2xl font-bold mb-4">Categorías</h2>
            <ul className="list-disc list-inside">
              {allPosts.reduce((categories, post) => {
                post.categories?.forEach(category => {
                  if (!categories.includes(category)) {
                    categories.push(category);
                  }
                });
                return categories;
              }, []).map((category, index) => (
                <li key={index}>
                  <button onClick={() => handleCategoryClick(category)} className="text-blue-500 hover:underline">
                    {category}
                  </button>
                </li>
              ))}
            </ul>
            <h2 className="text-2xl font-bold mb-4">Tags</h2>
            <div className="flex flex-wrap">
              {Object.keys(tagCounts).map((tag, index) => (
                <button
                  key={index}
                  onClick={() => handleTagClick(tag)}
                  className="bg-gray-200 text-gray-700 px-2 py-1 rounded mr-2 mb-2 hover:bg-gray-300"
                  style={{ fontSize: `${10 + tagCounts[tag] * 2}px` }}
                >
                  {tag}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;