import React from 'react';

const Contact = () => {
  return (
    <section id="contacto" className="support bg-white py-16 px-4 text-center">
      <h3 className="section-title text-3xl mb-10 font-bold">Contáctanos</h3>
      <div className="contact-form-container max-w-lg mx-auto bg-purple-700 p-8 rounded-lg shadow-md text-white">
        <form className="contact-form" action="https://formspree.io/f/mldrvvzq" method="POST">
          <div className="form-group mb-4 text-left">
            <label htmlFor="name" className="block mb-2 font-bold">Nombre:</label>
            <input type="text" id="name" name="name" required className="w-full p-2 border border-gray-300 rounded-lg" />
          </div>
          <div className="form-group mb-4 text-left">
            <label htmlFor="email" className="block mb-2 font-bold">Email:</label>
            <input type="email" id="email" name="email" required className="w-full p-2 border border-gray-300 rounded-lg" />
          </div>
          <div className="form-group mb-4 text-left">
            <label htmlFor="phone" className="block mb-2 font-bold">Número de Teléfono:</label>
            <input type="tel" id="phone" name="phone" required className="w-full p-2 border border-gray-300 rounded-lg" />
          </div>
          <div className="form-group mb-4 text-left">
            <label htmlFor="message" className="block mb-2 font-bold">Mensaje:</label>
            <textarea id="message" name="message" required className="w-full p-2 border border-gray-300 rounded-lg h-32 resize-vertical"></textarea>
          </div>
          <button type="submit" className="submit-button bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-blue-800 transition duration-300">Enviar</button>
        </form>
      </div>
      <p className="support-info mt-8 text-lg">
        O contáctanos por WhatsApp: <a href="https://wa.me/51967550393?text=Hola,%20necesito%20información%20de%20su%20app%20Mi%20Facturador%20para%20potenciar%20y%20gestionar%20mi%20negocio" className="contact-link text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer">Enviar mensaje por WhatsApp</a>
      </p>
    </section>
  );
};

export default Contact;