import React from 'react';
import mariaProfile from '../assets/images/maria-profile.jpg';
import carlosProfile from '../assets/images/carlos-profile.jpg';

const UseCase = () => {
  return (
    <section id="usecase" className="usecase bg-gray-100 py-16 px-4 text-center">
      <h3 className="section-title text-3xl mb-10">Experiencias de Éxito</h3>
      <div className="usecase-slider flex flex-col md:flex-row overflow-x-auto scroll-snap-x mandatory gap-5 py-5 justify-center">
        <div className="usecase-item bg-white p-5 rounded-lg shadow-md min-w-[300px] max-w-[400px] scroll-snap-align-start flex-shrink-0">
          <img src={mariaProfile} alt="Foto de María" className="usecase-photo w-20 h-20 rounded-full mb-5 mx-auto" />
          <blockquote className="text-lg mb-5 text-gray-600">
            "Esta aplicación ha transformado completamente la operación de mi negocio. La eficiencia y rapidez son incomparables."
          </blockquote>
          <p className="usecase-author text-gray-800">- María, Propietaria de Tienda</p>
        </div>
        <div className="usecase-item bg-white p-5 rounded-lg shadow-md min-w-[300px] max-w-[400px] scroll-snap-align-start flex-shrink-0">
          <img src={carlosProfile} alt="Foto de Carlos" className="usecase-photo w-20 h-20 rounded-full mb-5 mx-auto" />
          <blockquote className="text-lg mb-5 text-gray-600">
            "Jamás imaginé que la facturación electrónica podría ser tan sencilla. Es una herramienta indispensable que recomiendo ampliamente."
          </blockquote>
          <p className="usecase-author text-gray-800">- Carlos, Empresario</p>
        </div>
      </div>
    </section>
  );
};

export default UseCase;