import React from 'react';
import facturacionIcon from '../assets/images/facturacion-icon.png';
import inventarioIcon from '../assets/images/inventario-icon.png';
import ventasIcon from '../assets/images/ventas-icon.png';
import whatsappIcon from '../assets/images/whatsapp-icon.png';
import analiticaIcon from '../assets/images/analitica-icon.png';
import finanzasIcon from '../assets/images/finanzas-icon.png';

const Features = () => {
  return (
    <section id="caracteristicas" className="features bg-gray-100 py-16 px-4 text-center">
      <h3 className="section-title text-3xl mb-10">Características Innovadoras</h3>
      <div className="feature-grid grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="feature-item bg-white p-6 rounded-lg shadow-md transition-transform transform hover:-translate-y-2">
          <img src={facturacionIcon} alt="Ícono de facturación" className="mx-auto mb-4 max-w-24" />
          <h4 className="text-xl mb-2">Facturación Electrónica SUNAT</h4>
          <p className="text-gray-600">
            Emisión de documentos fiscales 100% conforme a las normativas vigentes, Facturas, Boletas, Notas de Crédito y Guías de Remisión.
          </p>
        </div>
        <div className="feature-item bg-white p-6 rounded-lg shadow-md transition-transform transform hover:-translate-y-2">
          <img src={inventarioIcon} alt="Ícono de inventario" className="mx-auto mb-4 max-w-24" />
          <h4 className="text-xl mb-2">Control de Inventarios Inteligente</h4>
          <p className="text-gray-600">
            Gestión avanzada de productos con control de inventario y seguimiento de lotes por fecha de vencimiento, incluyendo alertas automáticas.
          </p>
        </div>
        <div className="feature-item bg-white p-6 rounded-lg shadow-md transition-transform transform hover:-translate-y-2">
          <img src={ventasIcon} alt="Ícono de ventas" className="mx-auto mb-4 max-w-24" />
          <h4 className="text-xl mb-2">Ventas y Pedidos Optimizados</h4>
          <p className="text-gray-600">
            Sistema flexible para personalización, promociones, cotizaciones, notas de venta y carta QR.
          </p>
        </div>
        <div className="feature-item bg-white p-6 rounded-lg shadow-md transition-transform transform hover:-translate-y-2">
          <img src={whatsappIcon} alt="Ícono de whatsapp" className="mx-auto mb-4 max-w-24" />
          <h4 className="text-xl mb-2">Envío e Impresión de Documentos</h4>
          <p className="text-gray-600">
            Permite enviar comprobantes, cotizaciones y notas de crédito por WhatsApp y se puede imprimir en una impresora ticketera.
          </p>
        </div>
        <div className="feature-item bg-white p-6 rounded-lg shadow-md transition-transform transform hover:-translate-y-2">
          <img src={analiticaIcon} alt="Ícono de analítica" className="mx-auto mb-4 max-w-24" />
          <h4 className="text-xl mb-2">Analítica Empresarial</h4>
          <p className="text-gray-600">
            Potencia tu negocio con informes detallados para decisiones estratégicas. Genera reportes de ventas, stock y lotes de producción.
          </p>
        </div>
        <div className="feature-item bg-white p-6 rounded-lg shadow-md transition-transform transform hover:-translate-y-2">
          <img src={finanzasIcon} alt="Ícono de finanzas" className="mx-auto mb-4 max-w-24" />
          <h4 className="text-xl mb-2">Gestión Financiera Precisa</h4>
          <p className="text-gray-600">
            Control total de cajas y flujo de efectivo, incluyendo apertura y cierre de caja con diferentes tipos de roles.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Features;