import React from 'react';
import benefitsIllustration from '../assets/images/benefits-illustration.jpg'; // Importar la imagen desde la nueva ubicación

const Benefits = () => {
  return (
    <section id="beneficios" className="benefits bg-white py-10 px-4 text-center">
      <h3 className="section-title text-3xl mb-10">Ventajas Competitivas</h3>
      <div className="benefits-content flex flex-col lg:flex-row items-center justify-between max-w-full mx-auto">
        <ul className="benefit-list list-none p-0 m-0 flex-1 text-left lg:w-3/5">
          <li className="mb-5">
            <strong className="block text-lg mb-1">Compatibilidad Multiplataforma</strong>
            <span className="benefit-description text-gray-600 hidden lg:block">Mi Facturador APP está disponible en Android, iOS, Web y Mac, lo que permite a los negocios operar desde cualquier dispositivo. Esta flexibilidad asegura que los usuarios puedan gestionar ventas, inventarios y facturación desde cualquier lugar y en cualquier momento, brindando una experiencia continua y accesible.</span>
          </li>
          <li className="mb-5">
            <strong className="block text-lg mb-1">Alertas Proactivas de Inventario</strong>
            <span className="benefit-description text-gray-600 hidden lg:block">Sistema de alertas visuales para el control de stock y fechas de vencimiento, utilizando un semáforo de colores que ayuda a tomar decisiones rápidas para evitar pérdidas.</span>
          </li>
          <li className="mb-5">
            <strong className="block text-lg mb-1">Adaptabilidad y Personalización</strong>
            <span className="benefit-description text-gray-600 hidden lg:block">Ofrece opciones de personalización para los recibos, promociones, y precios, permitiendo a los negocios adaptar la app a sus necesidades específicas.</span>
          </li>
          <li className="mb-5">
            <strong className="block text-lg mb-1">Seguridad Robusta</strong>
            <span className="benefit-description text-gray-600 hidden lg:block">Sistema de autenticación y cifrado de datos que protege la información financiera y personal, garantizando transacciones seguras.</span>
          </li>
          <li className="mb-5">
            <strong className="block text-lg mb-1">Escalabilidad Empresarial</strong>
            <span className="benefit-description text-gray-600 hidden lg:block">Diseñada para crecer con tu negocio, permitiendo agregar nuevas funciones y usuarios según sea necesario sin complicaciones.</span>
          </li>
          <li className="mb-5">
            <strong className="block text-lg mb-1">Experiencia de Usuario Superior</strong>
            <span className="benefit-description text-gray-600 hidden lg:block">Interfaz intuitiva diseñada para una adopción rápida y sin complicaciones.</span>
          </li>
          <li className="mb-5">
            <strong className="block text-lg mb-1">Soporte y Actualizaciones Continuas</strong>
            <span className="benefit-description text-gray-600 hidden lg:block">Atención al cliente dedicada y actualizaciones frecuentes para incorporar nuevas funcionalidades y cumplir con las normativas fiscales actualizadas.</span>
          </li>
          <li className="mb-5">
            <strong className="block text-lg mb-1">Implementación Rápida y Sencilla</strong>
            <span className="benefit-description text-gray-600 hidden lg:block">Instalación y configuración rápidas, con soporte técnico disponible para garantizar una implementación sin problemas.</span>
          </li>
          <li className="mb-5">
            <strong className="block text-lg mb-1">Ideal para Pequeños y Medianos Negocios</strong>
            <span className="benefit-description text-gray-600 hidden lg:block">Diseñada pensando en la simplicidad y la eficiencia, esta app es perfecta para pequeños y medianos negocios que buscan digitalizar su gestión de ventas, mejorando su productividad y competitividad en el mercado.</span>
          </li>
          <li className="mb-5">
            <strong className="block text-lg mb-1">Atrae Más Clientes a Tu Local</strong>
            <span className="benefit-description text-gray-600 hidden lg:block">Al emitir comprobantes electrónicos válidos para sustentar gastos ante centros de trabajo o para reembolsos, tu negocio se convierte en una opción preferida para clientes que requieren comprobantes oficiales, aumentando la fidelidad del cliente y atrayendo más público al punto de venta.</span>
          </li>
        </ul>
        <img src={benefitsIllustration} alt="Ilustración de beneficios" className="benefits-image max-w-full h-auto mt-10 lg:mt-0 lg:ml-10 lg:w-2/5" />
      </div>
    </section>
  );
};

export default Benefits;