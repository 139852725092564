import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import Benefits from './components/Benefits';
import UseCase from './components/UseCase';
import CTA from './components/CTA';
import Screenshots from './components/Screenshots';
import FAQ from './components/FAQ';
import LastPost from './components/LastPost';
import Contact from './components/Contact';
import Footer from './components/Footer';
import AgendarCita from './components/AgendarCita';
import BlogPost from './components/BlogPost';
import Blog from './components/Blog';
import BlogGenerative from './components/BlogGenerative';
import PoliticasPrivacidad from './components/PoliticasPrivacidad';
import TerminosCondiciones from './components/TerminosCondiciones';
import SobreNosotros from './components/SobreNosotros';
import PlanesTarifas from './components/PlanesTarifas'; // Importa el componente PlanesTarifas
import ConsultaDocumentos from './components/ConsultaDocumentos'; // Importa el componente ConsultaDocumentos

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={
          <>
            <Hero />
            <section id="caracteristicas" className="pt-20">
              <Features />
            </section>
            <section id="beneficios" className="pt-20">
              <Benefits />
            </section>
            <section id="usecase" className="pt-20">
              <UseCase />
            </section>
            <section id="cta" className="pt-20">
              <CTA />
            </section>
            <section id="capturas" className="pt-20">
              <Screenshots />
            </section>
            <section id="planes-tarifas" className="pt-20">
              <PlanesTarifas />
            </section>
            <section id="faq" className="pt-20">
              <FAQ />
            </section>
            <section id="lastpost" className="pt-20">
              <LastPost />
            </section>
            <section id="contacto" className="pt-20">
              <Contact />
            </section>
          </>
        } />
        <Route path="/agendar-cita" element={<AgendarCita />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:slug" element={<BlogPost />} />
        <Route path="/generar-articulo" element={<BlogGenerative />} />
        <Route path="/politicas-privacidad" element={<PoliticasPrivacidad />} />
        <Route path="/terminos-condiciones" element={<TerminosCondiciones />} />
        <Route path="/sobre-nosotros" element={<SobreNosotros />} />
        <Route path="/consulta-documentos" element={<ConsultaDocumentos />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;