import React, { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  getDoc,
  serverTimestamp,
  doc,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { db, storage, auth } from "../config/firebaseConfig";
import axios from "axios";

const BlogGenerative = () => {
  const [topic, setTopic] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [article, setArticle] = useState(null);
  const [feedback, setFeedback] = useState("");
  const [seconds, setSeconds] = useState(0);
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [isApproved, setIsApproved] = useState(false);
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedAI, setSelectedAI] = useState("ollama"); // Estado para el selector de IA
  const [rememberMe, setRememberMe] = useState(false); // Estado para el checkbox "Recordar sesión"
  const [userData, setUserData] = useState({
    name: "",
    apellidos: "",
    rol: "",
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          console.log("Datos del usuario:", userData); // Imprimir datos del usuario por consola
          if (userData.activo && userData.rol === "admin") {
            setUser(user);
            setIsAdmin(true);
            setUserData({
              name: userData.name,
              apellidos: userData.apellidos,
              rol: userData.rol,
            });
          } else {
            setUser(null);
            setIsAdmin(false);
          }
        } else {
          setUser(null);
          setIsAdmin(false);
        }
      } else {
        setUser(null);
        setIsAdmin(false);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Cargar credenciales desde localStorage si existen
    const savedEmail = localStorage.getItem("email");
    const savedPassword = localStorage.getItem("password");
    if (savedEmail && savedPassword) {
      setEmail(savedEmail);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);

  useEffect(() => {
    let interval;
    if (isLoading) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    } else {
      clearInterval(interval);
      setSeconds(0);
    }
    return () => clearInterval(interval);
  }, [isLoading]);

        const generatePrompt = (topic, feedback = "") => {
      const articlePrompt = `
        Genera un artículo vendedor y optimizado para SEO sobre ${topic} en el contexto peruano. El artículo debe incluir los siguientes elementos:
        - Título: Un título atractivo y relevante.
        - Extracto: Un breve resumen del artículo.
        - Contenido: Un artículo detallado que cubra los siguientes puntos:
          1. Introducción a ${topic} en Perú.
          2. Beneficios de ${topic} para las empresas peruanas.
          3. Legislación y normativas vigentes en Perú sobre ${topic}.
          4. Cómo implementar ${topic} en una empresa peruana.
          5. Casos de éxito de empresas peruanas que han adoptado ${topic}.
          6. Cómo Mi Facturador puede ayudar en la implementación de ${topic}.
        - El contenido debe estar en formato HTML con estilos de Tailwind CSS, similar al siguiente ejemplo:
        
        <article class="prose lg:prose-xl mx-auto my-16 px-6">
          <section class="mt-16">
            <h2 class="text-3xl font-bold text-gray-800 pb-4 border-b border-gray-300">Subtítulo 1</h2>
            <p class="text-gray-700 leading-relaxed mt-8">Contenido del artículo.</p>
          </section>
          <section class="mt-16">
            <h2 class="text-3xl font-bold text-gray-800 pb-4 border-b border-gray-300">Subtítulo 2</h2>
            <p class="text-gray-700 leading-relaxed mt-8">Contenido del artículo.</p>
          </section>
          <section class="mt-16">
            <h2 class="text-3xl font-bold text-gray-800 pb-4 border-b border-gray-300">Subtítulo 3</h2>
            <p class="text-gray-700 leading-relaxed mt-8">Contenido del artículo.</p>
          </section>
          <section class="mt-16">
            <h2 class="text-3xl font-bold text-gray-800 pb-4 border-b border-gray-300">Subtítulo 4</h2>
            <p class="text-gray-700 leading-relaxed mt-8">Contenido del artículo.</p>
          </section>
          <section class="mt-16">
            <h2 class="text-3xl font-bold text-gray-800 pb-4 border-b border-gray-300">Subtítulo 5</h2>
            <p class="text-gray-700 leading-relaxed mt-8">Contenido del artículo.</p>
          </section>
          <section class="mt-16">
            <h2 class="text-3xl font-bold text-gray-800 pb-4 border-b border-gray-300">Subtítulo 6</h2>
            <p class="text-gray-700 leading-relaxed mt-8">Contenido del artículo.</p>
          </section>
        </article>
        
        - La respuesta debe estar en formato JSON con los siguientes campos:
          {
            "title": "Título del Artículo",
            "slug": "titulo-del-articulo",
            "excerpt": "Extracto del artículo.",
            "contentHTML": "<article class='prose lg:prose-xl mx-auto my-16 px-6'>...</article>",
            "tags": ["tag1", "tag2", "tag3"],
            "categories": ["category1", "category2", "category3"]
          }
        ${feedback ? `- Comentarios del usuario: ${feedback}` : ""}
      `;
    
      const imagePrompt = `Genera una imagen para el artículo titulado "${topic}" con las siguientes etiquetas: ["tag1", "tag2", "tag3"] y categorías: ["category1", "category2", "category3"].`;
    
      return `${articlePrompt.trim()}\n\n${imagePrompt.trim()}`;
    };

  // Función para generar el artículo usando la IA seleccionada
  const generateArticle = async (feedback = "") => {
    setIsLoading(true);
    setSeconds(0);

    // Iniciar un temporizador para actualizar el estado de la UI cada segundo
    const intervalId = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds + 1);
    }, 1000);

    try {
      const prompt = generatePrompt(topic, feedback); // Genera el prompt basado en el tema y comentarios

      console.log("Generated prompt:", prompt);

      let response;
      if (selectedAI === "ollama") {
        response = await generateArticleWithOllama(prompt);
      } else if (selectedAI === "openai") {
        response = await generateArticleWithOpenAI(prompt);
      } else if (selectedAI === "gemini") {
        response = await generateArticleWithGemini(prompt);
      }

      if (response.status === 200) {
        const generatedContent = response.data.content;

        setArticle({
          title: topic,
          excerpt: generatedContent.substring(0, 150), // Muestra un pequeño extracto
          contentHTML: generatedContent, // Artículo completo en formato HTML
        });

        setMessage("Artículo generado exitosamente.");
      } else {
        throw new Error(response.data.error.message || "Error al generar el artículo.");
      }
    } catch (error) {
      console.error("Error al generar el artículo:", error);
      setMessage("Error al generar el artículo.");
    } finally {
      clearInterval(intervalId); // Detener el temporizador
      setIsLoading(false);
    }
  };

  // Función para generar el artículo usando OpenAI
  const generateArticleWithOpenAI = async (prompt) => {
    try {
      const apiKey = process.env.REACT_APP_OPENAI_API_KEY; // Accede a la clave desde las variables de entorno

      console.log("API Key:", apiKey);

      const response = await fetch(
        "https://api.openai.com/v1/chat/completions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`, // Usa la API Key en la cabecera
          },
          body: JSON.stringify({
            model: "gpt-3.5-turbo", // Especifica el modelo que quieres usar
            messages: [
              {
                role: "system",
                content: "Eres un generador de artículos optimizado.",
              },
              { role: "user", content: prompt },
            ],
            max_tokens: 1000, // Ajusta el límite de tokens según lo que necesites
            temperature: 0.7,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        return { status: 200, data: { content: data.choices[0].message.content } };
      } else {
        throw new Error(data.error.message || "Error al generar el artículo.");
      }
    } catch (error) {
      console.error("Error al generar el artículo con OpenAI:", error);
      throw error;
    }
  };

  // Función para generar el artículo usando Ollama
  const generateArticleWithOllama = async (prompt) => {
    try {
      const data = JSON.stringify({
        model: "llama3.1",
        prompt: prompt,
        stream: false
      });

      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'http://localhost:11434/api/generate',
        headers: { 
          'Content-Type': 'application/json',
          //Authorization: `Bearer ${apiKey}` // Usa la API Key en la cabecera si es necesario
        },
        data: data,
        timeout: 300000 // Configura un tiempo de espera de 5 minutos (300,000 ms)
      };

      const response = await axios.request(config);

      if (response.status === 200) {
        return { status: 200, data: { content: response.data.content } };
      } else {
        throw new Error(response.data.error.message || "Error al generar el artículo.");
      }
    } catch (error) {
      console.error("Error al generar el artículo con Ollama:", error);
      throw error;
    }
  };

  const generateArticleWithGemini = async (feedback = "") => {
    setIsLoading(true);
    setMessage("");
    setArticle(null);
    setIsApproved(false);

    try {
      const prompt = generatePrompt(topic, feedback);
      console.log("Generated prompt:", prompt);

      // Crear un nuevo documento en la colección 'generate' con el prompt
      const generateRef = await addDoc(collection(db, "generate"), {
        prompt,
      });
      console.log("Document created with ID:", generateRef.id);

      // Esperar a que el artículo sea generado (esto puede depender de cómo se implemente la generación en tu backend)
      let articleData;
      while (true) {
        const docSnap = await getDoc(generateRef);
        if (docSnap.exists()) {
          console.log("Document snapshot:", docSnap.data());
          if (docSnap.data().status.state === "COMPLETED") {
            articleData = docSnap.data();
            break;
          }
        }
        await new Promise((resolve) => setTimeout(resolve, 2000)); // Esperar 2 segundos antes de volver a verificar
      }

      let { response } = articleData;
      console.log("Response before cleaning:", response);
      response = response.replace(/^```json/, "").replace(/```$/, ""); // Eliminar las etiquetas ```json y ```
      console.log("Response after cleaning:", response);
      const articleJSON = JSON.parse(response);
      console.log("Parsed JSON:", articleJSON);

      setArticle(articleJSON);
    } catch (error) {
      console.error("Error al generar el artículo:", error);
      setMessage("Error al generar el artículo.");
    } finally {
      setIsLoading(false);
    }
  };


    const generateSeoFileName = (title) => {
    // Normalizar el título para eliminar los acentos
    const normalizedTitle = title.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    
    // Convertir a minúsculas y reemplazar caracteres no permitidos por guiones
    return normalizedTitle.toLowerCase().replace(/[^a-z0-9]+/g, "-") + ".jpg";
  };

  const uploadImage = async (file, title) => {
    if (!file || !user) {
      console.error("No file or user not authenticated");
      return;
    }

    try {
      const seoFileName = generateSeoFileName(title);
      const storageRef = ref(storage, `blog/images/${seoFileName}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef); // Obtener la URL de descarga
      setImageUrl(url);
      console.log("Image uploaded successfully:", url);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const approveArticle = () => {
    setIsApproved(true);
  };

  const saveArticle = async () => {
    if (!article) return;

    try {
      // Guardar el artículo generado en la colección 'blogPost'
      await addDoc(collection(db, "blogPost"), {
        title: article.title,
        slug: article.slug,
        excerpt: article.excerpt,
        contentHTML: article.contentHTML,
        date: serverTimestamp(),
        tags: article.tags,
        categories: article.categories,
        imageUrl: imageUrl,
      });

      setMessage("Artículo generado y guardado exitosamente.");
      setArticle(null);
      setImage(null);
      setImageUrl("");
      setIsApproved(false);
    } catch (error) {
      console.error("Error al guardar el artículo:", error);
      setMessage("Error al guardar el artículo.");
    }
  };

  const rejectArticle = () => {
    setArticle(null);
    setMessage("Por favor, proporciona comentarios sobre el error.");
  };

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      const user = auth.currentUser;
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        console.log("Datos del usuario:", userData); // Imprimir datos del usuario por consola
        if (userData.activo && userData.rol === "superAdmin") {
          setUser(user);
          setIsAdmin(true);
          setUserData({
            name: userData.name,
            apellidos: userData.apellidos,
            rol: userData.rol,
          });
          setMessage("Inicio de sesión exitoso.");
          if (rememberMe) {
            // Guardar credenciales en localStorage si "Recordar sesión" está marcado
            localStorage.setItem("email", email);
            localStorage.setItem("password", password);
          } else {
            // Limpiar credenciales de localStorage si "Recordar sesión" no está marcado
            localStorage.removeItem("email");
            localStorage.removeItem("password");
          }
        } else {
          setMessage("No tienes permisos de administrador.");
        }
      } else {
        setMessage("Usuario no encontrado.");
      }
    } catch (error) {
      console.error("Error al iniciar sesión:", error);
      setMessage("Error al iniciar sesión.");
    }
  };
  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setIsAdmin(false);
      setUserData({ name: "", apellidos: "", rol: "" });
      setMessage("Cierre de sesión exitoso.");
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
      setMessage("Error al cerrar sesión.");
    }
  };

    return (
    <div className="container mx-auto p-6 mt-16 flex">
      <div className="w-3/4 pr-6">
        <h1 className="text-4xl font-bold mb-6">
          Generar Artículo para el Blog
        </h1>
        {isAdmin ? (
          <>
            <input
              type="text"
              className="w-full p-2 mb-4 border rounded"
              placeholder="Escribe el tema para generar el artículo..."
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
            />
            <select
              className="w-full p-2 mb-4 border rounded"
              value={selectedAI}
              onChange={(e) => setSelectedAI(e.target.value)}
            >
              <option value="ollama">Ollama</option>
              <option value="openai">OpenAI</option>
              <option value="gemini">Gemini</option>
            </select>
            <button
              className="w-full bg-blue-500 text-white p-2 rounded mt-4"
              onClick={() => generateArticle()}
              disabled={isLoading}
            >
              {isLoading ? "Generando..." : "Generar Artículo"}
            </button>
            {isLoading && (
              <p className="mt-4 text-blue-500">
                Tiempo transcurrido: {seconds} segundos
              </p>
            )}
            {article && (
              <div className="mt-8">
                <h2 className="text-2xl font-bold mb-4">
                  Revisión del Artículo Generado
                </h2>
                <div
                  dangerouslySetInnerHTML={{ __html: article.contentHTML }}
                />
                <div className="mt-4">
                  <button
                    className="bg-green-500 text-white p-2 rounded mr-2"
                    onClick={approveArticle}
                  >
                    Aprobar
                  </button>
                  <button
                    className="bg-red-500 text-white p-2 rounded"
                    onClick={rejectArticle}
                  >
                    Rechazar
                  </button>
                </div>
                <textarea
                  className="w-full p-2 mt-4 border rounded"
                  placeholder="Proporciona comentarios sobre el error..."
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                />
                <button
                  className="w-full bg-blue-500 text-white p-2 rounded mt-4"
                  onClick={() => generateArticle(feedback)}
                  disabled={isLoading}
                >
                  {isLoading
                    ? "Generando..."
                    : "Generar Artículo con Comentarios"}
                </button>
              </div>
            )}
            {isApproved && (
              <div className="mt-8">
                <input
                  type="file"
                  className="w-full p-2 mb-4 border rounded"
                  onChange={handleImageChange}
                />
                <button
                  className="w-full bg-blue-500 text-white p-2 rounded mt-4"
                  onClick={() => uploadImage(image, article.title)}
                >
                  Subir Imagen
                </button>
                <button
                  className="w-full bg-green-500 text-white p-2 rounded mt-4"
                  onClick={saveArticle}
                >
                  Guardar Artículo
                </button>
              </div>
            )}
          </>
        ) : (
          <p className="text-red-500">
            Debes iniciar sesión como administrador para generar artículos.
          </p>
        )}
        {message && <p className="mt-4 text-green-500">{message}</p>}
      </div>
      <div className="w-1/4 pl-6">
        {!user ? (
          <div className="mb-6">
            <h2 className="text-2xl font-bold mb-4">Iniciar Sesión</h2>
            <input
              type="email"
              className="w-full p-2 mb-4 border rounded"
              placeholder="Correo electrónico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              className="w-full p-2 mb-4 border rounded"
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="mb-4">
              <input
                type="checkbox"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
              <label className="ml-2">Recordar sesión</label>
            </div>
            <button
              className="w-full bg-blue-500 text-white p-2 rounded"
              onClick={handleLogin}
            >
              Iniciar Sesión
            </button>
          </div>
        ) : (
          <div>
            <div className="mb-4">
              <p>
                <strong>Nombre:</strong> {userData.name}
              </p>
              <p>
                <strong>Apellidos:</strong> {userData.apellidos}
              </p>
              <p>
                <strong>Rol:</strong> {userData.rol}
              </p>
            </div>
            <button
              className="w-full bg-red-500 text-white p-2 rounded mb-6"
              onClick={handleLogout}
            >
              Cerrar Sesión
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogGenerative;