import React from 'react';
import googlePlay from '../assets/images/google_play.jpg';
import appStore from '../assets/images/app_store.jpg';
import web from '../assets/images/web.jpg';

const CTA = () => {
  return (
    <section className="cta bg-blue-900 text-white py-16 px-4 text-center">
      <h2 className="section-title text-3xl mb-5">Potencia tu Negocio Hoy</h2>
      <a href="agendar-cita" className="download-button inline-block py-3 px-6 bg-green-600 text-white no-underline rounded text-lg mb-5 transition duration-300 hover:bg-green-700">
        Agenda una Demostración y Transforma tu Gestión Empresarial
      </a>
      <p className="app-availability text-lg mb-5">Disponible en</p>
      <div className="cta-images flex justify-center gap-5 flex-wrap">
        <a href="https://play.google.com/store/apps/details?id=pe.perubi.MiFacturador" target="_blank" rel="noopener noreferrer">
          <img src={googlePlay} alt="Mi Facturador Google Play" className="w-full max-w-xs h-auto rounded-lg transition-transform duration-300 hover:scale-105" />
        </a>
        <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
          <img src={appStore} alt="Mi Facturador App Store" className="w-full max-w-xs h-auto rounded-lg transition-transform duration-300 hover:scale-105" />
        </a>
        <a href="https://mifacturador.net.pe/app" target="_blank" rel="noopener noreferrer">
          <img src={web} alt="Mi Facturador Web" className="w-full max-w-xs h-auto rounded-lg transition-transform duration-300 hover:scale-105" />
        </a>
      </div>
    </section>
  );
};

export default CTA;