import React from 'react';
import { Link } from 'react-router-dom';
import iphoneMockup from '../assets/images/iphone-mockup.png'; // Importar la imagen desde la nueva ubicación

const Hero = () => {
  return (
    <section id="inicio" className="hero bg-gradient-to-r from-blue-900 to-purple-400 text-white p-4 flex flex-col md:flex-row items-center justify-between overflow-hidden min-h-screen mt-16 md:mt-0">
      <div className="hero-content flex-1 p-4 max-w-2xl">
        <h1 className="headline text-3xl mb-6">Revoluciona tu Negocio con Nuestro APP Mi Facturador, Punto de Venta y Facturación Electrónica</h1>
        <h2 className="subheadline text-xl mb-6">Gestión Integral de Ventas, Inventarios y Documentos Fiscales - 100% Conforme con SUNAT</h2>
        <div className="cta-buttons flex gap-4 flex-wrap">
          <Link to="/agendar-cita" className="cta-button bg-blue-500 text-white py-2 px-4 rounded transition duration-300 hover:bg-blue-700">Agendar una Demostración</Link>
          <a href="https://wa.me/51967550393?text=Hola,%20necesito%20información%20de%20su%20app%20Mi%20Facturador%20para%20potenciar%20y%20gestionar%20mi%20negocio" className="cta-button whatsapp-button bg-green-500 text-white py-2 px-4 rounded transition duration-300 hover:bg-green-700" target="_blank" rel="noopener noreferrer">Contactar por WhatsApp</a>
        </div>
      </div>
      <div className="hero-image flex-1 flex justify-center items-center max-w-full h-auto mt-6 md:mt-0">
        <img src={iphoneMockup} alt="App en iPhone" className="iphone-mockup w-3/4 md:w-1/2 lg:w-1/3 h-auto" />
      </div>
    </section>
  );
};

export default Hero;